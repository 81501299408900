import { getCurrentInstance, toRefs, ref, reactive } from "vue";
import { useRouter } from 'vue-router'
import { baseUrlImg } from '@/utils/common'
import { dateToStrCopy } from '@/utils/date'
import Compressor from "compressorjs"

export default {
	setup(props, context) {

		const { proxy } = getCurrentInstance();

		const router = useRouter();
		
		// 绑定设备的编码添加
		const facilityQrNumber = proxy.$router.currentRoute.value.query.facilityQrNumber

		const dataAll = reactive({
			title_span: ref(null),
			
			facilityOperationList: ref(null),
			facilityOperationEn: ref("请选择操作人员"),
			facilityOperationShow: ref(false),

			minFacilityRegister: new Date(2010, 0, 1),
			maxFacilityRegister: new Date(2110, 11, 31),
			currentFacilityRegister: new Date(),
			facilityRegisterShow: ref(false),
			facilityRegisterEn: ref("请选择注册登记日期"),

			facilityYearShow: ref(false),
			facilityYearList: ref(null),
			facilityYearEn: ref('请选择设计使用年限'),

			minFacilityCheck: new Date(2010, 0, 1),
			maxFacilityCheck: new Date(2110, 11, 31),
			currentFacilityCheck: new Date(),
			facilityCheckShow: ref(false),
			facilityCheckEn: ref("请选择设备检验日期"),

			minFacilityCheck_next: new Date(),
			facilityCheckNext: new Date(),
			facilityCheckNextShow: ref(false),
			facilityCheckNextEn: ref("请选择下次设备检验日期"),

			facilityFocusList: ref(null),
			facilityFocusEn: ref("请选择是否为重点监控"),
			facilityFocusShow: ref(false),

			facilityStreetList: ref(null),
			facilityStreetEn: ref("请选择省市区街道"),
			facilityStreetShow: ref(false),

			facilityStateList: ref(null),
			facilityStateEn: ref("请选择设备状态"),
			facilityStateShow: ref(false),

			facilityTypeList: ref(null),
			facilityTypeEn: ref("请选择设备类型"),
			facilityTypeShow: ref(false),

			facilityUserResult: ref([]),
			facilityUserEn: ref("请选择监管人员"),
			facilityUserShow: ref(false),
			facilityUserList: ref([]),
			facilityUserResultName: ref([]),

			facilitySrcList: ref([]),
			srcList: ref([]),

			facilityId: proxy.$router.currentRoute.value.params.facilityId,
			facilityName: ref(null),
			facilityOtherUser: ref(null),
			facilityOwner: ref(null),
			facilityEnterprise: sessionStorage.getItem("enterpriseId"),
			enterpriseName: sessionStorage.getItem("enterpriseName"),
			facilityOperation: ref(null),
			facilityAddress: ref(null),
			facilityArchives: ref(null),
			facilityRegisterNumber: ref(null),
			facilityRegister: ref(null),
			facilityYear: ref(null),
			facilityMake: ref(null),
			facilityNumber: ref(null),
			facilityCheck: ref(null),
			facilityPlace: ref(null),
			facilityFocus: ref(null),
			facilityStreet: ref(null),
			facilityModel: ref(null),
			facilityState: ref(null),
			facilityType: ref(null),
			facilityUser: ref(null),
			facilityNote: ref(null),
			facilitySrc: ref(null),
			facilityStarts: ref(null),
			defaultIndex: ref(null),
			loading: ref(false)
		});

		// 初始化
		if (dataAll.facilityId != null && dataAll.facilityId != "") {
			dataAll.title_span = '编辑设备信息'

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/getFacility`),
				method: 'post',
				data: proxy.$http.adornData({ 'facilityId': dataAll.facilityId }) ,
				headers: {
					token: sessionStorage.getItem("userToken")
				},
			}).then(({ data }) => {
				if (data && data.code === 0) {
					if (data.data != null) {
						Object.assign(dataAll, data.data);
						console.log(dataAll, data)
						if (dataAll.facilityRegister) {
							dataAll.currentFacilityRegister = new Date(dataAll.facilityRegister);
							dataAll.facilityRegisterEn = dataAll.facilityRegister
						}
						
						
						if (dataAll.facilityCheck) {
							dataAll.currentFacilityCheck = new Date(dataAll.facilityCheck);
							dataAll.facilityCheckEn = dataAll.facilityCheck
						}

						if (dataAll.facilityCheckNext) {
							dataAll.facilityCheckNextEn = dataAll.facilityCheckNext;
							dataAll.facilityCheckNext = new Date(dataAll.facilityCheckNext);
						}

						if (dataAll.userTsMapList != null && dataAll.userTsMapList[0] != null && dataAll.userTsMapList[0]["userId"] != null) {
							let nameArr = dataAll.userTsMapList.map((u) => { return u.userNikeName})
							dataAll.facilityUserEn = nameArr? (nameArr.length>1? nameArr.join('、'):nameArr[0]) : '';
							dataAll.facilityUserResult = dataAll.userTsMapList.map((u) => { return u.userId});
							dataAll.facilityUserResultName = nameArr;
						}

						if (dataAll.facilitySrc) {
							dataAll.srcList = dataAll.facilitySrc.split(",")
							for (var i = 0; i < dataAll.srcList.length; i++) {
								dataAll.facilitySrcList.push({ url: baseUrlImg() + dataAll.srcList[i] });
							}
						} else {
							dataAll.facilitySrcList = ref([]);
						}
					} else {
						proxy.$toast.fail("参数缺失！");
					}
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})

			if (dataAll.facilityRegister) {
				dataAll.facilityRegisterEn = dataAll.facilityRegister;
				dataAll.facilityRegister = dataAll.facilityRegister;
			}
			if (dataAll.facilityCheck) {
				dataAll.facilityCheckEn = dataAll.facilityCheck;
				dataAll.facilityCheck = dataAll.facilityCheck;
			}
			if(dataAll.facilityCheckNext) {
				dataAll.facilityCheckNextEn = dateToStrCopy(new Date(dataAll.facilityCheck));
				dataAll.facilityCheck = dataAll.facilityCheck;
			}
			if (dataAll.facilityUser) {
				dataAll.facilityUserResult = dataAll.facilityUser.split(",");
			}
		} else {
			dataAll.title_span = '新增设备'
			if (sessionStorage.getItem("userTsMapList")&&sessionStorage.getItem("userTsMapList")!=='null') {
				let array = JSON.parse(sessionStorage.getItem('userTsMapList'));
				for (var i = 0; i < array.length; i++) {
					dataAll.facilityUserResultName.push(array[i].userNikeName);
					dataAll.facilityUserResult.push(parseInt(array[i].userId));
				}
				dataAll.facilityUserEn = dataAll.facilityUserResultName.join('、');
				dataAll.facilityUser = dataAll.facilityUserResult.join(",");
			}
		}


		const fan = () => {
			dataAll.facilityId||facilityQrNumber? router.go(-1) : router.push({ path: '/enterpriseAdd/2' });
		}

		// 操作人员
		const selectFacilityOperation = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/enterpriseUser`),
				method: 'post',
				data: {
					"enterpriseId": sessionStorage.getItem("enterpriseId")
				},
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				// console.log(data);
				if (data && data.code === 0) {
					const array = [];
					if (data.date) {
						for (var i = 0; i < data.date.length; i++) {
							array.push({
								text: data.date[i].userName,
								id: data.date[i].userId
							});
						}
					}
					dataAll.facilityOperationList = array;
					dataAll.defaultIndex = dataAll.facilityOperationList.findIndex((ace) => ace.id == dataAll.facilityOperation);
					dataAll.facilityOperationShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({ path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQRNumber") });
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const facilityOperationConfirm = (value) => {
			dataAll.facilityOperationShow = false;
			dataAll.facilityOperationEn = value.text;
			dataAll.facilityOperation = value.id;
		}
		const facilityOperationCancel = () => {
			dataAll.facilityOperationShow = false;
		}

		// 注册登记日期
		const selectFacilityRegister = () => {
			dataAll.facilityRegisterShow = true;
		}
		const facilityRegisterConfirm = (value) => {
			dataAll.facilityRegisterEn = dateToStrCopy(new Date(value));
			dataAll.facilityRegister = dateToStrCopy(new Date(value));
			dataAll.facilityRegisterShow = false;
		}
		const facilityRegisterCancel = () => {
			dataAll.facilityRegisterShow = false;
		}

		// 设备检查日期
		const selectFacilityCheck = () => {
			dataAll.facilityCheckShow = true;
		}
		const facilityCheckConfirm = (value) => {
			dataAll.facilityCheckEn = dateToStrCopy(new Date(value));
			dataAll.facilityCheck = dateToStrCopy(new Date(value));
			dataAll.facilityCheckShow = false;
		}
		const facilityCheckCancel = () => {
			dataAll.facilityCheckShow = false;
		}

		// 下次设备检查日期
		const selectCheckNext = () => {
			dataAll.facilityCheckNextShow = true
		}
		const facilityCheckNextConfirm = (value) => {
			dataAll.facilityCheckNextEn = dateToStrCopy(new Date(value));
			dataAll.facilityCheckNextShow = false;
		}
		const facilityCheckNextCancel = () => {
			dataAll.facilityCheckNextShow = false;
		}
		
		// 设计使用年限
		const selectFacilityYear = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getYer`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				console.log(data);
				if (data && data.code === 0) {
					const array = [];
					if (data.type) {
						for (var i = 0; i < data.type.length; i++) {
							array.push({ text: data.type[i].value, id: data.type[i].key });
						}
					}
					dataAll.facilityYearList = array;
					dataAll.defaultIndex = dataAll.facilityYearList.findIndex((ace) => ace.id == dataAll.facilityYear);
					dataAll.facilityYearShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQRNumber")});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const facilityYearConfirm = (value) => {
			dataAll.facilityYearEn = value.text;
			dataAll.facilityYear = value.id;
			dataAll.facilityYearShow = false;
		}
		const facilityYearCancel = () => {
			dataAll.facilityYearShow = false;
		}

		// 重点监控设备
		const selectFacilityFocus = () => {
			dataAll.facilityFocusList = [
				{ text: "普通", id: 0 }, 
				{ text: "重点", id: 1 },
			];
			dataAll.defaultIndex = dataAll.facilityFocusList.findIndex((ace) => ace.id == dataAll.facilityFocus);
			dataAll.facilityFocusShow = true;
		}
		const facilityFocusConfirm = (value) => {
			dataAll.facilityFocusShow = false;
			dataAll.facilityFocusEn = value.text;
			dataAll.facilityFocus = value.id;
		}
		const facilityFocusCancel = () => {
			dataAll.facilityFocusShow = false;
		}

		// 省市区街道
		const selectFacilityStreet = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getArea`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					if (data.type) {
						for (var i = 0; i < data.type.length; i++) {
							array.push({
								text: data.type[i].areaName,
								id: data.type[i].areaId
							});
						}
					}
					dataAll.facilityStreetList = array;
					dataAll.defaultIndex = dataAll.facilityStreetList.findIndex((ace) => ace.id == dataAll.facilityStreet);
					dataAll.facilityStreetShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQRNumber")
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const facilityStreetConfirm = (value) => {
			dataAll.facilityStreetShow = false;
			dataAll.facilityStreetEn = value.text;
			dataAll.facilityStreet = value.id;
		}
		const facilityStreetCancel = () => {
			dataAll.facilityStreetShow = false;
		}

		//设备状态
		const selectFacilityState = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getFacilityStatic`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					if (data.type) {
						for (var i = 0; i < data.type.length; i++) {
							array.push({
								text: data.type[i].value,
								id: data.type[i].key
							});
						}
					}
					dataAll.facilityStateList = array;
					dataAll.defaultIndex = dataAll.facilityStateList.findIndex((ace) => ace.id == dataAll
						.facilityState);
					dataAll.facilityStateShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQRNumber")
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const facilityStateConfirm = (value) => {
			dataAll.facilityStateShow = false;
			dataAll.facilityStateEn = value.text;
			dataAll.facilityState = value.id;
		}
		const facilityStateCancel = () => {
			dataAll.facilityStateShow = false;
		}

		//设备类型
		const selectFacilityType = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/type/getFacility`),
				method: 'post',
				data: null,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				if (data && data.code === 0) {
					const array = [];
					if (data.type) {
						for (var i = 0; i < data.type.length; i++) {
							array.push({
								text: data.type[i].value,
								id: data.type[i].key
							});
						}
					}
					dataAll.facilityTypeList = array;
					dataAll.defaultIndex = dataAll.facilityTypeList.findIndex((ace) => ace.id == dataAll.facilityType);
					dataAll.facilityTypeShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQRNumber")
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const facilityTypeConfirm = (value) => {
			dataAll.facilityTypeShow = false;
			dataAll.facilityTypeEn = value.text;
			dataAll.facilityType = value.id;
		}
		const facilityTypeCancel = () => {
			dataAll.facilityTypeShow = false;
		}

		//监管人员
		const selectFacilityUser = () => {
			proxy.$http({
				url: proxy.$http.adornUrl(`/sys/user/listAll`),
				method: 'post',
				data: {
					'userGame': sessionStorage.getItem("enterpriseGame")
				},
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({ data }) => {
				if (data && data.code === 0) {
					dataAll.facilityUserList = data.list;
					dataAll.facilityUserShow = true;
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem("enterpriseQRNumber")
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}
		const facilityUserCancel = () => {
			dataAll.facilityUserShow = false;
			if(sessionStorage.getItem("userTsMapList")&&sessionStorage.getItem("userTsMapList")!=='null') {
				let userMap = JSON.parse(sessionStorage.getItem("userTsMapList"))
				let nameArr = userMap.map((u) => { return u.userNikeName})
				dataAll.facilityUserEn = nameArr? (nameArr.length>1? nameArr.join('、') : nameArr[0]) : '请选择监管人员';
				dataAll.facilityUserResult = dataAll.userTsMapList.map((u) => { return u.userId});
				dataAll.facilityUserResultName = nameArr;
			}else{
				dataAll.facilityUserEn = '请选择监管人员'
				dataAll.facilityUserResult = []
				dataAll.facilityUserResultName = []
			}
		}
		const facilityUserConfirm = (value) => {
			dataAll.facilityUserEn = dataAll.facilityUserResultName.join('、');
			if (dataAll.facilityUserEn.length > 20) {
				dataAll.facilityUserEn = dataAll.facilityUserEn.substr(0, 20) + "..."
			}
			if (dataAll.facilityUserEn.length == 0) {
				dataAll.facilityUserEn = "请选择监管人员";
			}
			dataAll.facilityUser = dataAll.facilityUserResult.join(",");
			dataAll.facilityUserShow = false;
		}
		
		const facilityUserToggle = (item) => {
			const index = dataAll.facilityUserResult.indexOf(item.userId);
			if (index == -1) {
				dataAll.facilityUserResult.push(item.userId);
				dataAll.facilityUserResultName.push(item.userName);
			} else {
				dataAll.facilityUserResult.splice(index, 1);
				dataAll.facilityUserResultName.splice(index, 1);
			}
		}

		const addFacilitySrc = (file) => {
			proxy.$toast.loading({ message: '上传中...', forbidClick: true })
			new Compressor(file, {
				quality: 0.4,
				success(res) {
					const formData = new FormData();
					formData.append('file', res, res.name);
					proxy.$http({
						url: proxy.$http.adornUrl(`/app/enterprise/addFile`),
						method: 'post',
						data: formData,
						headers: {
							token: sessionStorage.getItem("userToken")
						},
						cache: false,
					}).then(({ data }) => {
						proxy.$toast.clear()
						if (data && data.code === 0) {
							dataAll.srcList.push(data.data);
							dataAll.facilitySrc = dataAll.srcList.join(",");
							dataAll.facilitySrcList = dataAll.srcList.map(f=>{
								return { url: baseUrlImg() + f }
							})
						} else if (data.code === 401) {
							sessionStorage.setItem("userToken", "");
							router.push({
								path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
							});
						} else {
							proxy.$toast.fail(data.msg);
						}
					})
				},
				error(res) {
					console.log(res)
				}
			})
		}
		
		const facilitySrcImg = (file, detail) => {
			dataAll.srcList.splice(detail.index, 1);
			dataAll.facilitySrc = dataAll.srcList.join(',');
			dataAll.facilitySrcList = dataAll.srcList.map(f=>{
				return { url: baseUrlImg() + f }
			})
		}
		
		// 新增或更新设备
		const addF = () => {
			dataAll.loading = true
			let jumpPath = dataAll.facilityId ||facilityQrNumber ? '':'/enterpriseAdd/2'
			if (!dataAll.facilityState && dataAll.facilityState != 0) {
				proxy.$toast.fail("请选择设备状态");
				dataAll.loading = false
				return;
			}
			if (!dataAll.facilityType) {
				proxy.$toast.fail("请选择设备类型");
				dataAll.loading = false
				return;
			}
			
			let json = {
				facilityId: dataAll.facilityId,
				facilityName: dataAll.facilityName,
				facilityOtherUser: dataAll.facilityOtherUser,
				facilityOwner: dataAll.facilityOwner,
				facilityEnterprise: dataAll.facilityEnterprise,
				facilityOperation: dataAll.facilityOperation,
				facilityAddress: dataAll.facilityAddress,
				facilityArchives: dataAll.facilityArchives,
				facilityRegisterNumber: dataAll.facilityRegisterNumber,
				facilityRegister: dataAll.facilityRegister,
				facilityYear: dataAll.facilityYear,
				facilityMake: dataAll.facilityMake,
				facilityNumber: dataAll.facilityNumber,
				facilityCheck: dataAll.facilityCheck,
				facilityTime: dataAll.facilityTime,
				facilityPlace: dataAll.facilityPlace,
				facilityFocus: dataAll.facilityFocus,
				facilityStreet: dataAll.facilityStreet,
				facilityModel: dataAll.facilityModel,
				facilityState: dataAll.facilityState,
				facilityType: dataAll.facilityType,
				facilityUser: dataAll.facilityUser,
				facilityNote: dataAll.facilityNote,
				facilitySrc: dataAll.facilitySrc,
				facilityStarts: dataAll.facilityStarts,
				facilityCheckNext: dataAll.facilityCheckNextEn
			};

			// 绑定设备的编码添加
			if(facilityQrNumber) { json.facilityQrNumber = facilityQrNumber }

			proxy.$http({
				url: proxy.$http.adornUrl(`/app/qr/addUpdateFacility`),
				method: 'post',
				data: json,
				headers: {
					token: sessionStorage.getItem("userToken")
				}
			}).then(({
				data
			}) => {
				// console.log(data);
				dataAll.loading = false
				if (data && data.code === 0) {
					jumpPath? router.push({ path: jumpPath }) : router.go(-1);
				} else if (data.code === 401) {
					sessionStorage.setItem("userToken", "");
					router.push({
						path: '/enterpriseJump/' + sessionStorage.getItem('enterpriseQrNumber')
					});
				} else {
					proxy.$toast.fail(data.msg);
				}
			})
		}

		return {
			...toRefs(dataAll),
			fan,
			selectFacilityOperation,
			facilityOperationConfirm,
			facilityOperationCancel,
			selectFacilityRegister,
			facilityRegisterConfirm,
			facilityRegisterCancel,
			selectFacilityCheck,
			facilityCheckConfirm,
			facilityCheckCancel,
			selectFacilityFocus,
			facilityFocusConfirm,
			facilityFocusCancel,
			selectFacilityStreet,
			facilityStreetConfirm,
			facilityStreetCancel,
			selectFacilityState,
			facilityStateConfirm,
			facilityStateCancel,
			selectFacilityType,
			facilityTypeConfirm,
			facilityTypeCancel,
			selectFacilityUser,
			facilityUserCancel,
			facilityUserConfirm,
			facilityUserToggle,
			addFacilitySrc,
			facilitySrcImg,
			selectCheckNext,
			facilityCheckNextConfirm,
			facilityCheckNextCancel,
			selectFacilityYear,
			facilityYearConfirm,
			facilityYearCancel,
			addF
		}
	}
}
