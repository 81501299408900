<template>
	<div>
		<div class="top-title">
			<van-icon name="arrow-left" class="icon-fan" @click="fan()" />
			<span>{{title_span}}</span>
		</div>

		<div class="alls">
			<van-row class="van-row">
				<van-col span="9" class="text-left">设备名称</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityName" placeholder="请输入设备名称" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">所属企业</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="enterpriseName" readonly />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">操作人员</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityOperation()">{{facilityOperationEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设备地址</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityAddress" placeholder="请输入设备地址" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">档案编号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityArchives" placeholder="请输入档案编号" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">注册代码</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityRegisterNumber" placeholder="请输入注册代码" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">注册登记日期</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityRegister()">{{facilityRegisterEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">使用登记证号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityStarts" placeholder="请输入使用登记证号" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设计使用年限</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityYear()">
					{{facilityYearEn}}
					<!-- <van-field v-model="facilityYear" placeholder="请输入设计使用年限" /> -->
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">制造单位</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityMake" placeholder="请输入制造单位" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">出厂编号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityNumber" placeholder="请输入出厂编号" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设备检验日期</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityCheck()">{{facilityCheckEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">下次设备检验日期</van-col>
				<van-col span="15" class="text-right" @click="selectCheckNext()">{{ facilityCheckNextEn }}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设备使用地点</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityPlace" placeholder="请输入设备使用地点" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">重点监控设备</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityFocus()">{{facilityFocusEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">省市区街道</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityStreet()">{{facilityStreetEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设备型号</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityModel" placeholder="请输入设备型号" />
				</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设备状态</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityState()">{{facilityStateEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">设备类型</van-col>
				<van-col span="15" class="text-right" @click="selectFacilityType()">{{facilityTypeEn}}</van-col>
			</van-row>

			<van-row class="van-row">
				<van-col span="9" class="text-left">监管人员</van-col>
				<van-col span="15" class="text-right text-hide" @click="selectFacilityUser()">{{facilityUserEn}}</van-col>
			</van-row>
			
			<van-row class="van-row">
				<van-col span="9" class="text-left">使用方</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityOtherUser" placeholder="请输入使用方名称" />
				</van-col>
			</van-row>
			
			<van-row class="van-row">
				<van-col span="9" class="text-left">所属方</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityOwner" placeholder="请输入所属方名称" />
				</van-col>
			</van-row>

			<van-row class="van-row-img">
				<van-col span="9" class="text-left">备注</van-col>
				<van-col span="15" class="text-right">
					<van-field v-model="facilityNote" rows="4" autosize type="textarea" style="height:3.066667rem;margin-top: .266667rem" placeholder="请输入备注" show-word-limit />
				</van-col>
			</van-row>

			<van-row class="van-row-img-3">
				<van-col span="9" class="text-left">上传设备图片</van-col>
				<van-col span="15" class="text-right">
					<van-uploader v-model="facilitySrcList" multiple :before-read="addFacilitySrc" :max-count="3" preview-full-image :preview-options="{closeable: true}" @delete="facilitySrcImg" />
				</van-col>
			</van-row>

			<div class="submit-type">
				<van-button block type="info" color="#1989FA" @click="addF()" :loading="loading" :disabled="loading" class="submit-button">提交</van-button>
			</div>
		</div>

		<!-- 操作人员 -->
		<van-popup v-model:show="facilityOperationShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="操作人员" show-toolbar :default-index="defaultIndex" :columns="facilityOperationList" @confirm="facilityOperationConfirm" @cancel="facilityOperationCancel" />
		</van-popup>

		<!-- 注册登记日期 -->
		<van-popup v-model:show="facilityRegisterShow" round position="bottom" :style="{ width: '100%' }">
			<van-datetime-picker v-model="currentFacilityRegister" type="date" title="注册登记日期" :min-date="minFacilityRegister" :max-date="maxFacilityRegister" @confirm="facilityRegisterConfirm" @cancel="facilityRegisterCancel" />
		</van-popup>

		<!-- 设计使用年限 -->
		<van-popup v-model:show="facilityYearShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="设计使用年限" show-toolbar :default-index="defaultIndex" :columns="facilityYearList" @confirm="facilityYearConfirm" @cancel="facilityYearCancel" />
		</van-popup>

		<!-- 设备检查日期 -->
		<van-popup v-model:show="facilityCheckShow" round position="bottom" :style="{ width: '100%' }">
			<van-datetime-picker v-model="currentFacilityCheck" type="date" title="设备检查日期" :min-date="minFacilityCheck" :max-date="maxFacilityCheck" @confirm="facilityCheckConfirm" @cancel="facilityCheckCancel" />
		</van-popup>

		<!-- 下次设备检查日期 -->
		<van-popup v-model:show="facilityCheckNextShow" round position="bottom" :style="{ width: '100%' }">
			<van-datetime-picker v-model="facilityCheckNext" type="date" title="下次设备检查日期" :min-date="minFacilityCheck_next" :max-date="maxFacilityCheck" @confirm="facilityCheckNextConfirm" @cancel="facilityCheckNextCancel" />
		</van-popup>

		<!-- 重点监控设备 -->
		<van-popup v-model:show="facilityFocusShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="重点监控设备" show-toolbar :default-index="defaultIndex" :columns="facilityFocusList" @confirm="facilityFocusConfirm" @cancel="facilityFocusCancel" />
		</van-popup>

		<!-- 省市区街道 -->
		<van-popup v-model:show="facilityStreetShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="省市区街道" show-toolbar :default-index="defaultIndex" :columns="facilityStreetList" @confirm="facilityStreetConfirm" @cancel="facilityStreetCancel" />
		</van-popup>

		<!-- 设备状态 -->
		<van-popup v-model:show="facilityStateShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="设备状态" show-toolbar :default-index="defaultIndex" :columns="facilityStateList" @confirm="facilityStateConfirm" @cancel="facilityStateCancel" />
		</van-popup>

		<!-- 设备类型 -->
		<van-popup v-model:show="facilityTypeShow" round position="bottom" :style="{ width: '100%' }">
			<van-picker title="设备类型" show-toolbar :default-index="defaultIndex" :columns="facilityTypeList" @confirm="facilityTypeConfirm" @cancel="facilityTypeCancel" />
		</van-popup>

		<!-- 监管人员 -->
		<van-popup v-model:show="facilityUserShow" position="bottom" :style="{ width: '100%' }">
			<div class="van-picker__toolbar">
				<button type="button" class="van-picker__cancel" @click="facilityUserCancel">取消</button>
				<button type="button" class="van-picker__confirm" @click="facilityUserConfirm">确认</button>
			</div>
			<div class="checkbox-con" style="max-height:7.04rem;overflow-y:auto">
				<van-checkbox-group v-model="facilityUserResult">
					<van-cell-group>
						<van-cell class="van-cell-class" v-for="(item, index) in facilityUserList" clickable :key="index" :title="item.userName" @click="facilityUserToggle(item)">
							<template #right-icon>
								<van-checkbox :name="item.userId" ref="checkboxes" />
							</template>
						</van-cell>
					</van-cell-group>
				</van-checkbox-group>
			</div>
		</van-popup>
	</div>
</template>
<style scoped>
	.alls {
		padding-bottom: 2rem;
	}

	.top-title {
		background-color: #025193;
		color: white;
		width: 100%;
		height: 1.173333rem;
		line-height: 1.173333rem;
		position: fixed;
		z-index: 999;
		text-align: center;
		font-size: 0.49rem;
		letter-spacing: 0.026667rem;
	}

	.icon-fan {
		position: absolute;
		left: 0.133333rem;
		top: 0.24rem;
		font-size: 0.746667rem;
	}

	.alls {
		padding-top: 1.173333rem;
		width: 100%;
	}

	.van-row {
		height: 1.066667rem;
		line-height: 1.066667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.text-left {
		font-size: 0.373333rem;
		padding: 0px 0.133333rem;
	}

	.text-right {
		font-size: 0.373333rem;
		padding: 0px 0.133333rem;
	}

	.van-cell {
		padding: 0.15rem 0.133333rem;
	}

	.van-row-img {
		height: 3.466667rem;
		line-height: 3.466667rem;
		border-bottom: 1px #eeeeee solid;
	}

	.van-uploader {
		margin-top: 0.266667rem;
	}

	.text-right1 {
		font-size: 0.373333rem;
		padding: 0px;
		text-align: center;
	}

	.submit-type {
		width: 100%;
		height: 1.45rem;
		padding-top: 0.15rem;
		background-color: white;
		position: fixed;
		bottom: 0px;
		left: 0rem;
	}

	.submit-button {
		width: 90%;
		margin-left: 5%;
		height: 1.2rem;
		border-radius: 0.16rem;
	}

	.van-row-img-3 {
		min-height: 3.466667rem;
		height: auto;
		line-height: 3.466667rem;
		border-bottom: 1px #eeeeee solid;
	}
</style>
<script>
	import EquipmentAddJs from "../../js/add/EquipmentAdd";
	export default {
		...EquipmentAddJs,
	};
</script>
